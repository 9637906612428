import React, { Component } from "react"
import style from "./Callout.module.scss"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import CanvasFrame from "../../../canvas/CanvasFrame"
import Misc from "../../../../utils/misc"

const q = graphql`
  query {
    ieo: file(relativePath: { eq: "images/ieo.png" }) {
      childImageSharp {
        fixed(width: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    lafemma: file(relativePath: { eq: "images/lafamma.png" }) {
      childImageSharp {
        fixed(width: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    
    life: file(relativePath: { eq: "images/life.png" }) {
      childImageSharp {
        fixed(width: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

class Callout extends Component {
  constructor(props) {
    super(props)
    this.state = { mx: 0, my: 0, showIndex: Misc.getRandomNum(0,2) }
    this.heroCanvas = React.createRef();

    this.drawHeroDots = this.drawHeroDots.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.createDots = this.createDots.bind(this)
    this.animateDots = this.animateDots.bind(this)
    this.onArrowClick = this.onArrowClick.bind(this)

    this.dots = {
      nb: 100,
      distance: 60,
      d_radius: 100,
      array: [],
    }

    this.mousePosition = {
      x: 0,
      y: 0,
    }
  }

  onMouseMove(e) {
    let offsetTop = this.wraperElm.getBoundingClientRect().y
    this.mousePosition.mx = e.nativeEvent.clientX
    this.mousePosition.my = e.nativeEvent.clientY - offsetTop
  }

  drawHeroDots(canvas, ctx) {
    let dotColor = "#4D286F"
    let lineColor = "#4D286F"
    if (canvas) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.style.display = "block"
      ctx.fillStyle = dotColor
      ctx.lineWidth = 0.1
      ctx.strokeStyle = lineColor;
      this.createDots(canvas, ctx)
      this.animateDots(canvas, ctx)
    }
  }

  createDots(canvas, ctx) {
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    let dot = undefined
    let n = this.dots.nb
    for (let i = 0; i < n; i++) {
      this.dots.array.push(new this.Dot(canvas))
      dot = this.dots.array[i]
      dot.create(ctx)
    }
  }

  animateDots(canvas, ctx) {
    let n = this.dots.nb
    let dots = this.dots.array
    let d = this.dots.distance
    let r = this.dots.d_radius
    for (let i = 0; i < n; i++) {
      let dot = dots[i]

      if (dot.y < 0 || dot.y > canvas.height) {
        dot.vy = -dot.vy
      } else if (dot.x < 0 || dot.x > canvas.width) {
        dot.vx = -dot.vx
      }
      dot.x += dot.vx
      dot.y += dot.vy
      for (let j = 0; j < n; j++) {
        let i_dot = dots[i]
        let j_dot = dots[j]
        if (
          i_dot.x - j_dot.x < d &&
          i_dot.y - j_dot.y < d &&
          i_dot.x - j_dot.x > -d &&
          i_dot.y - j_dot.y > -d
        ) {
          if (
            i_dot.x - this.mousePosition.mx < r &&
            i_dot.y - this.mousePosition.my < r &&
            i_dot.x - this.mousePosition.mx > -r &&
            i_dot.y - this.mousePosition.my > -r
          ) {
            ctx.beginPath()
            ctx.moveTo(i_dot.x, i_dot.y)
            ctx.lineTo(j_dot.x, j_dot.y)
            ctx.stroke()
            ctx.closePath()
          }
        }
      }
    }
  }

  onArrowClick(isLeft) {
    let idx = this.state.showIndex
    let max = 2
    let min = 0
    if (isLeft) {
      idx = idx - 1
      if (idx < 0) idx = max
    } else {
      idx = idx + 1
      if (idx > max) idx = min
    }
    this.setState({ showIndex: idx })
  }

  componentDidMount(){
    if (!this.wraperElm) {
      this.wraperElm = document.querySelector("#callOutWrapper")
    }
  }

  Dot(canvas){
    this.x = Math.random() * canvas.width
    this.y = Math.random() * canvas.height
    this.vx = -0.5 + Math.random()
    this.vy = -0.5 + Math.random()
    this.radius = Math.random()
    this.create = function(ctx) {
      ctx.beginPath()
      ctx.arc(this.x, this.y, this.radius * 1.5, 0, Math.PI * 2, false)
      ctx.fill()
    }
  }

  projectItem(props, data) {
    let item;
    if(this.state.showIndex === 0){
      item = <div className={style.project_item}>
        <div className={style.project_text_wrapper}>
          <h4>
            <strong>iExpenseOnline</strong>
            <br />
            <small>A 100% Free Personal Finance Tracker</small>
          </h4>
          <p>
            Need to create a monthly budget? Want to pay off a student
            loan faster or simply track your expenses? iEO is a 100%
            free personal finance tracker that helps you stay on top of
            your finances.
          </p>
          <a
            className={"fun"}
            href="https://iexpenseonline.com"
            target={"_blank"}
          >
            Check it out
          </a>
        </div>
        <Img
          fixed={data.ieo.childImageSharp.fixed}
          className={style.sidekick_img_wrapper}
        />
      </div>;
    }
    else if(this.state.showIndex === 1){
      item = <div className={style.project_item}>
        <div className={style.project_text_wrapper}>
          <h4>
            <strong>LaFemma Nadia</strong>
            <br />
            <small>
              Nadia Lbaz Aesthetics At Face Time is here for you.
            </small>
          </h4>
          <p>
            Give yourself a break and spend some time relaxing and
            receiving revitalizing treatments at the hands of our
            professional, highly trained staff at LaFemma Nadia Studio.
          </p>
          <a
            className={"fun"}
            href="http://lafemmenadia.net"
            target={"_blank"}
          >
            Launch Site
          </a>
        </div>
        <Img
          fixed={data.lafemma.childImageSharp.fixed}
          className={style.sidekick_img_wrapper}
        />
      </div>;
    }
    else{
      item = <div className={style.project_item}>
        <div className={style.project_text_wrapper}>
          <h4>
            <strong>WhatIsMeaningOfLife.com</strong>
            <br />
            <small>
              Explore Ideas, Find <strong>ANSWERS</strong>, Inspire{" "}
              <u>Others</u>
            </small>
          </h4>
          <p>
            A site dedicated to exploring answers to the ultimate
            question. What is the meaning of life? It contains a
            large repository of ideas, concepts, and explanations for
            your enjoyment.
          </p>
          <a
            className={"fun"}
            href="http://whatismeaningoflife.com/"
            target={"_blank"}
          >
            Launch Site
          </a>
        </div>
        <Img
          fixed={data.life.childImageSharp.fixed}
          className={style.sidekick_img_wrapper}
        />
      </div>;
    }
    return item;
  }

  render() {
    return (
      <StaticQuery
        query={q}
        render={data => (
          <div
            id={"callOutWrapper"}
            className={style.main_wrapper}
            onMouseMove={this.onMouseMove}
          >
            <div id={"projects"} className={style.link_anchor}/>
            <CanvasFrame
              ref={this.heroCanvas}
              className={style.hero_canvas}
              paint={this.drawHeroDots}
            loopIt={true}
              background={"#765285"}
              ratio={1}
            />
            <div className={style.wrapper}>
              <h3 className={"sectionHeader"}>Featured Projects</h3>
              <div
                className={"arrow " + style.leftArrow}
                onClick={this.onArrowClick}
              />
              <div
                className={"arrow " + style.rightArrow}
                onClick={this.onArrowClick}
              />
              {this.projectItem(this.props, data)}
            </div>
          </div>
        )}
      />
    )
  }
}

export default Callout
