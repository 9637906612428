import React, { Component } from "react";
import style from "./SideKick.module.scss";
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const q = graphql`
  query {
    file(relativePath: { eq: "icons/sidekick_v4.png" }) {
      childImageSharp {
        fluid(maxHeight: 250) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

class SideKick extends Component {
  render() {
    return (
      <StaticQuery
        query={q}
        render={data => (
          <div className={style.main_wrapper}>
            <div className={style.wrapper}>
              <Img
                fluid={data.file.childImageSharp.fluid}
                className={style.sidekick_img_wrapper}
              />
              <h4>
                It's more than a <strong>website</strong>.
                It's your <strong>dream</strong>.
              </h4>
              <p>
                And it takes more than a great design to be successful. For your business to get found online, your website
                needs to be as responsive, stable, and professional as you are. We are here to help!
              </p>
            </div>
          </div>

        )}
      />
    );
  }
}

export default SideKick;
