import React, { Component } from "react"
import Misc from "../../utils/misc"

class CanvasFrame extends Component {
  constructor(props) {
    super(props)
    this.draw = this.draw.bind(this)
    this.updateDimensions = this.updateDimensions.bind(this)
    this.state = {
      width: "0",
      height: "0",
      ratio: 1,
    }

    this.canvas = React.createRef()
    this.wrapper = React.createRef()
  }

  setDimensions() {

    const wrapper = this.wrapper.current
    const ratio = this.props.ratio || window.devicePixelRatio || 1;
    const width = (wrapper.clientWidth * ratio) | 0
    const height = (wrapper.clientHeight * ratio) | 0
    const resizing = false
    this.setState({ width, height, ratio, resizing })
  }

  updateDimensions() {
    this.setDimensions()
  }

  componentDidMount() {
    this.setDimensions()
    ///window.addEventListener("resize", this.updateDimensions)
  }

  componentWillUnmount() {
    //window.removeEventListener("resize", this.updateDimensions)
    this.setDimensions()
  }

  componentDidUpdate(prevProps, prevState, snap) {
    if (!prevProps.loop) {
      this.draw()
    }
  }

  draw() {
    if (this.state) {
      const { width, height, ratio } = this.state
      const canvas = this.canvas.current;

      if (Misc.isPartiallyVisible(canvas)) {
        let context = canvas.getContext("2d")
        context.width = width
        context.height = height
        context.pixelRatio = ratio
        if (this.props.clear) {
          context.clearRect(0, 0, canvas.width, canvas.height)
        }
        if (this.props.paint) {
          this.props.paint(canvas, context)
        }
      }
    }
    if (this.props.loopIt) {
      window.requestAnimationFrame(this.draw)
    }
  }

  render() {
    const canvas = this.state ? (
      <canvas
        ref={this.canvas}
        width={this.state.width}
        height={this.state.height}
        onMouseMove={this.props.onMouseMove ? this.props.onMouseMove : null}
        style={{
          width: "100%",
          height: "100%",
          background: this.props.background,
        }}
      />
    ) : null

    return (
      <div
        ref={this.wrapper}
        className={this.props.className ? this.props.className : "view"}
        style={this.props.style}
      >
        {canvas}
      </div>
    )
  }
}

export default CanvasFrame
