import React from "react"
import SectionWrapper from "../components/layout/wrappers/SectionWrapper"
import Hero from "../components/page/index/hero/Hero"
import SideKick from "../components/page/index/sidekick/SideKick"
import Callout from "../components/page/index/call_out/Callout"
import Features from "../components/page/index/features/Features"
import VisitorWrapper from "../components/layout/wrappers/VisitorWrapper"
import WhyUs from "../components/page/index/whyus/WhyUs"
import Contact from "../components/page/index/contact/Contact"

export default props => (
  <VisitorWrapper>
    <Hero />
    <SideKick />
    <Features />
    <Callout />
    <SectionWrapper>
      <WhyUs />
    </SectionWrapper>
    <Contact/>
  </VisitorWrapper>
)
