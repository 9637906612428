import React, { Component } from "react";
import style from "./Footer.module.scss";

class Footer extends Component {

  render() {
    return (
      <div className={style.wrapper}>
        <div className={style.footer_body}>
          <label>© Copyright 2019 Lamiglo LLC. All rights reserved.</label>
        </div>
      </div>
    );
  }
}

export default Footer;
