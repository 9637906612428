import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import style from "./Hero.module.scss";

//https://www.gatsbyjs.org/packages/gatsby-image/

const q = graphql`
  query {
    file(relativePath: { eq: "icons/hero_v3.png" }) {
      childImageSharp {
        fixed(height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

class Hero extends Component {
  render() {
    return (
      <StaticQuery
        query={q}
        render={data => (
          <div className={style.wrapper}>
            <div className={style.hero_body}>
              <Img
                fixed={data.file.childImageSharp.fixed}
                className={style.hero_img}
              />
              <div className={style.hero_text_wrapper}>
                <h1>
                  <strong>we build</strong>
                  <br/>
                  awesome & affordable websites.
                </h1>
                <h2>
                  Your website is the core of your online presence.<br/>Make it count!
                </h2>
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}

export default Hero;
