const misc = {
  isPartiallyVisible(el) {
    let rect = el.getBoundingClientRect()
    let elemTop = rect.top
    let elemBottom = rect.bottom
    // Only completely visible elements return true:
    //var isCompletelyVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    // Partially visible elements return true:
    let isPartiallyVisible = elemTop < window.innerHeight && elemBottom >= 0
    return isPartiallyVisible
  },

  getRandomNum(min, max) {
    return Math.round(Math.random() * (max - min) + min)
  },
}

export default misc
