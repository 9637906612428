import React, { Component } from "react"
import style from "./Contact.module.scss"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const q = graphql`
  query {
    file(relativePath: { eq: "icons/email_us_v6.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

class Contact extends Component {

  render() {
    return (
      <StaticQuery
        query={q}
        render={data => (
          <div className={style.main_wrapper}>
            <div id={"contact"} className={style.link_anchor} />
            <div className={style.wrapper}>
              <h3 className={"sectionHeader"}>Contact Us</h3>
              <div className={style.contact_wrapper}>
                <p>
                  We understand that finding the right consulting company
                  is a big deal. It may even be the most critical business
                  decision you make in the next few years. We are here to help!{" "}
                  <u>Let’s start something great together.</u>
                  <br />
                  Ready to jumpstart our discussion?
                </p>
                <a
                  className={"fun"}
                  href="mailto:contact@lamiglo.com?Subject=Hello%20again"
                  target="_top"
                >
                  contact@lamiglo.com
                </a>
                <p>We'll then set up a time to discuss.</p>
                <Img
                  fluid={data.file.childImageSharp.fluid}
                  className={style.contact_img_wrapper}
                />
              </div>
            </div>
          </div>
        )}
      />
    )
  }
}

export default Contact
