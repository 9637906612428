import React, { Component } from "react";
import style from "./Features.module.scss";
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image";
import Misc from "../../../../utils/misc"

const q = graphql`
  query {
    feature1 : file(relativePath: { eq: "icons/feature1_v6.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    
    feature2 : file(relativePath: { eq: "icons/feature2_v2.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    
    feature3 : file(relativePath: { eq: "icons/feature3_v4.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    
    feature4 : file(relativePath: { eq: "icons/feature4_v8.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

class Features extends Component {
  constructor(props) {
    super(props);
    this.busy = false;
    this.onScroll = this.onScroll.bind(this);
    this.state = {
      f1Shown : false,
      f2Shown : false,
      f3Shown : false,
      f4Shown : false,

    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
    if(!this.children){
      this.children = {
        "f1" : document.querySelector("#f1"),
        "f2" : document.querySelector("#f2"),
        "f3" : document.querySelector("#f3"),
        "f4" : document.querySelector("#f4")
      }
      this.onScroll();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll(){
    if(this.busy)
      return;
    this.busy = true;
    setTimeout(function(){
      this.busy = false;
      var newState = {};
      if(Misc.isPartiallyVisible(this.children["f1"]))
        newState.f1Shown = true;
      if(Misc.isPartiallyVisible(this.children["f2"]))
        newState.f2Shown = true;
      if(Misc.isPartiallyVisible(this.children["f3"]))
        newState.f3Shown = true;
      if(Misc.isPartiallyVisible(this.children["f4"]))
        newState.f4Shown = true;
      this.setState(newState);
    }.bind(this), 0);
    if(this.state.f1Shown && this.state.f2Shown && this.state.f3Shown && this.state.f4Shown){
      window.removeEventListener("scroll", this.onScroll);
    }
  }

  render() {
    const f1ACls = (this.state.f1Shown) ? style.showLeft : "";
    const f1BCls = (this.state.f1Shown) ? style.showRight : "";
    const f2ACls = (this.state.f2Shown) ? style.showRight : "";
    const f2BCls = (this.state.f2Shown) ? style.showLeft : "";
    const f3ACls = (this.state.f3Shown) ? style.showLeft : "";
    const f3BCls = (this.state.f3Shown) ? style.showRight : "";
    const f4ACls = (this.state.f4Shown) ? style.showRight : "";
    const f4BCls = (this.state.f4Shown) ? style.showLeft : "";
    return (
      <StaticQuery
        query={q}
        render={data => (
          <div className={style.wrapper}>
            <div id={"services"} className={style.link_anchor}/>
            <div className={style.content_wrapper}>
              <h3 className={'sectionHeader'}>Our <strong>Services</strong></h3>
              <div className={style.item_wrapper}>
                <div id={"f1"} className={style.row}>
                  <div className={style.item + " " + style.A + " " + f1ACls}>
                    <Img
                      fluid={data.feature1.childImageSharp.fluid}
                      className={style.featured_image}
                    />
                  </div>
                  <div className={style.item + " " + style.B + " " + f1BCls}>
                    <h5>
                      "You do you" while we build it for you.
                    </h5>
                    <p>
                      From customer interview to site launch, our team of experts develop your website so you can concentrate on what you do best - running your business.
                    </p>
                    <ul>
                      <li>
                        Mobile-friendly web page design
                      </li>
                      <li>
                        SEO-friendly copywriting
                      </li>
                      <li>
                        Website quality control
                      </li>
                    </ul>
                  </div>
                </div>
                <div id={"f2"} className={style.row}>

                  <div className={style.item + " " + style.B + " " + f2BCls}>
                    <h5>
                      Words. Visuals. Functions. A total design philosophy.
                    </h5>
                    <p>
                      Good looks may attract visitors, but truly good design keeps them close. Our human-centric design fuses content, typography, structure, imagery and intuitive functionality to create meaningful experiences across every touch point.
                    </p>
                  </div>
                  <div className={style.item + " " + style.A + " " + f2ACls}>
                    <Img
                      fluid={data.feature2.childImageSharp.fluid}
                      className={style.featured_image}
                    />
                  </div>
                </div>
                <div id={"f3"} className={style.row}>
                  <div className={style.item + " " + style.A + " " + f3ACls}>
                    <Img
                      fluid={data.feature3.childImageSharp.fluid}
                      className={style.featured_image}
                    />
                  </div>
                  <div className={style.item + " " + style.B + " " + f3BCls}>
                    <h5>
                      We strive to create digital experiences that merge creativity with advanced technology that drives results.
                    </h5>
                    <p>
                      Passion is the heart of our craft, technology its living soul. Take advantage of our exposure to, and intimate knowledge of, the world’s leading technologies—across all platforms.
                    </p>
                  </div>
                </div>
                <div id={"f4"} className={style.row}>
                  <div className={style.item + " " + style.B + " " + f4BCls}>
                    <h5>
                      We aren't just a site development service.
                    </h5>
                    <p>
                      We're the whole package. We can help you take your business or an idea to the next level. We offer number of business impactful solutions such as:
                    </p>
                    <ul>
                      <li>
                        Site & Social Media Marketing
                      </li>
                      <li>
                        Business Plan & Seed Funding Prep
                      </li>
                      <li>
                        A 360 degree review of your website or business
                      </li>
                    </ul>
                  </div>
                  <div className={style.item + " " + style.A + " " + f4ACls}>
                    <Img
                      fluid={data.feature4.childImageSharp.fluid}
                      className={style.featured_image}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}

export default Features;
