import React, { Component } from "react"
import style from "./WhyUs.module.scss"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const q = graphql`
  query {
    file(relativePath: { eq: "icons/whyus_v3.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

class WhyUs extends Component {
  render() {
    return (
      <StaticQuery
        query={q}
        render={data => (
          <div className={style.wrapper}>
            <div id={"whyUs"} className={style.link_anchor}/>
            <h3 className={"sectionHeader"}>Why Choose us?</h3>

            <p className={style.under_header}>
              As your partner, we work with you to create content and analize
              results. We plan, execute, and measure your marketing strategy and
              answer any questions along the way. Here are some of the reasons
              we think you should consider working with us.
            </p>
            <div className={style.content_wrapper}>
              <div className={style.content}>
                <h5>We Create Experiences</h5>
                <p>
                  We create experiences that are attractive, simple to use, and
                  drive results for your business. We are not just a web
                  development agency. We are dedicated and focused on making
                  things work for you and your business.
                </p>
                <h5>We Emphasize Simplicity.</h5>
                <p>
                  Some firms love to build complicated sites. Not us. We choose
                  the simplest tool that will effectively accomplish a task.
                  Simple sites load faster, are easy to use and are less prone
                  to problems.
                </p>
                <h5>We Ask, Listen and Understand</h5>
                <p>
                  We begin each web development project by gaining a clear
                  understanding of your idea or product. This helps us create a
                  blueprint solution that communicates the expected message and
                  unique brand identity. We communicate progress and check in
                  with you every step of the way, incorporating feedback until
                  the product is finalized. It is a collaborative process of
                  discovery that takes patience, experience, and communication.
                </p>
                <h5>We Are on Time and on Budget</h5>
                <p>
                  We determine the scope of the project, create a budget, road
                  map, and timeline. Once they are approved, we stick to them.
                  Just to make sure, we do regular status checks on progress and
                  deadlines to keep expectations on track and communication
                  channels open.
                </p>
                <h5>We treat you as a partner.</h5>
                <p>
                  Our business has grown word-of-mouth and we intend to continue
                  growing this way. We'll treat you fairly and do a great job so
                  that you'll want to continue working with us after your site
                  has launched. And, we want you to tell all your friends and
                  colleagues about us. We'll give you options and the tools and
                  information to make good choices. We'll give you our
                  recommendations, but ultimately, every decision about your
                  website is yours.
                </p>
              </div>
              <Img
                fluid={data.file.childImageSharp.fluid}
                className={style.img_wrapper}
              />
            </div>
          </div>
        )}
      />
    )
  }
}

export default WhyUs
