import React, { Component } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import style from "./Header.module.scss"

const q = graphql`
  query {
    file(relativePath: { eq: "icons/logo_v2.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxHeight: 75) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

class Header extends Component {
  constructor(props) {
    super(props)
    this.onMenuClick = this.onMenuClick.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.state = {
      menu_open: false,
      showSignIn: props.headerState !== "SignIn",
      showRegister: props.headerState !== "Register",
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside)
  }

  onMenuClick() {
    const currentState = this.state.menu_open
    this.setState({ menu_open: !currentState })
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      //alert('You clicked outside of me!');
      if (this.state.menu_open) {
        this.onMenuClick()
      }
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  render() {
    const barCls = this.state.menu_open
      ? style.menu_icon + " " + style.menu_icon_open
      : style.menu_icon
    const wrapperCls = this.state.menu_open
      ? style.link_wrapper + " " + style.link_wrapper_open
      : style.link_wrapper
    return (
      <StaticQuery
        query={q}
        render={data => (
          <div className={style.wrapper}>
            <div ref={this.setWrapperRef} className={style.body}>
              <Link to={`/`} className={style.logo_link}>
                <Img
                  fluid={data.file.childImageSharp.fluid}
                  className={style.logo_img_wrapper}
                />
                <label>
                  <div>L</div>
                  <div>a</div>
                  <div>m</div>
                  <div>i</div>
                  <div>g</div>
                  <div>l</div>
                  <div>o</div>
                  <div><small>.com</small></div>
                </label>
              </Link>
              <h5 className={wrapperCls}>
                <Link to={`/#services`}>
                  Services
                </Link>
                <Link to={`/#projects`}>
                  Projects
                </Link>
                <Link to={`/#whyUs`}>
                  Why Us?
                </Link>
                <Link to={`/#contact`}>
                  Contact
                </Link>
              </h5>
              <div onClick={this.onMenuClick} className={barCls}>
                <div className={style.bar} />
                <div className={style.bar} />
                <div className={style.bar} />
              </div>
            </div>
          </div>
        )}
      />
    )
  }
}

export default Header
